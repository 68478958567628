<template>
  <div class="card card-custom" style="margin-top: 12px;">
    <div class="px-5">
      <v-row class="mt-5">
        <v-col cols="3">
          <!-- Content for the first column -->
          <h3 class="card-label text-primary font-weight-bolder">Menu Categories</h3>
        </v-col>
        <v-col cols="3">
          <b-form-file
            v-model="categoryFile"
            placeholder="Choose a file to upload"
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </v-col>
        <v-col cols="4">
          <div class>
            <v-btn @click="save" :disabled="!categoryFile" class="mt-1 bg-primary text-light">
              <v-icon left>flaticon2-add-1</v-icon>Upload Categories
            </v-btn>
          </div>
        </v-col>
        <v-col cols="2">
          <!-- Add New Button -->
          <v-btn @click="addNew" class="ma-2 bg-primary text-light" outlined>
            <v-icon left>flaticon2-add-1</v-icon>Add New
          </v-btn>
        </v-col>
        <!-- Content for the second column -->
      </v-row>
      <!-- <div class="card-title">
        <span class="card-icon">
          <i class="flaticon2-chat-1 text-primary"></i>
        </span>
        <h3 class="card-label font-poppins">Menu Categories</h3>
      </div>-->
      <div class="card-header mt-5 d-flex justify-content-between align-items-center">
        <!-- <div class="card-toolbar">
        <b-form-file
          v-model="categoryFile"
          placeholder="Choose a file to upload"
          drop-placeholder="Drop file here..."
        ></b-form-file>
        <button
          v-on:click="save"
          v-if="categoryFile != ''"
          class="btn btn-success">
          <i class="flaticon2-add-1"></i>Upload Categories
        </button>
        <a href="javascript:;" @click="addNew" class="btn btn-sm btn-success font-weight-bold">
          <i class="flaticon2-add-1"></i>Add New
        </a>
        </div>-->
      </div>
    </div>

    <div class="card-body pt-1 pb-3">
      <!-- <div class="form-group row">
        <label class="col-lg-1 col-xs-4 col-form-label">Rows</label>
        <b-form-select
          class="col-lg-1"
          v-model="limit"
          @change="get"
          :options="$store.state.profile.rows"
        ></b-form-select>
        <label class="col-lg-5"></label>
        <input
          type="text"
          v-model="search"
          @keyup.enter="get"
          style="border-bottom: 1px solid lightgray; border-radius: 1px"
          class="col-lg-5 form-control-solid form-control-lg"
          placeholder="Search in Categories"
        />
      </div>-->
      <br />
      <v-data-table
        :headers="headers"
        :items="categories"
        :items-per-page="limit"
        :page.sync="currentPage"
        :server-items-length="totalCategories"
        class="elevation-1"
        @update:page="get"
      >
        <template v-slot:item.name="{ item }">
          <span class="d-block font-size-md">{{ item.name }}</span>
        </template>
        <template v-slot:item.products="{ item }">
          <span class="d-block font-size-md">{{ item.products }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon medium class="mr-2" @click="edit(item.id)" sixz>mdi-pencil</v-icon>
          <v-icon medium @click="deleteItem(item.id)">mdi-delete</v-icon>
        </template>
        <template
          v-slot:footer.page-text
        >{{ `Displaying ${startItem} - ${endItem} of ${totalCategories} items` }}</template>
      </v-data-table>

      <!-- Vuetify Pagination -->
      <v-pagination v-model="currentPage" :length="totalPages" :total-visible="7" @input="get"></v-pagination>
    </div>
    <b-modal id="category-modal" size="lg" ref="category-modal" title="Add Category" hide-footer>
      <MenuCategoryForm :id="currentId" @onClickCancel="hideModal" @success="formSuccess" />
    </b-modal>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import MenuCategoryForm from "@/view/pages/menu/form-components/MenuCategoryForm";

export default {
  name: "Categories",
  components: {
    MenuCategoryForm
  },
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Products", value: "products" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      dialog: false,
      singleSelect: false,
      search: "",
      selected: [],
      limit: 5,
      currentPage: 1,
      totalCategories: 0,
      totalPages: 1,
      categories: [],
      currentId: "",
      categoryFile: null
    };
  },
  computed: {
    startItem() {
      return (this.currentPage - 1) * this.limit + 1;
    },
    endItem() {
      return Math.min(this.currentPage * this.limit, this.totalCategories);
    }
  },
  methods: {
    save() {
      let formData = new FormData();
      if (this.categoryFile) {
        formData.append("file", this.categoryFile);
        ApiService.fileUpload("business/import/categories", formData)
          .then(({ data }) => {
            if (data == 1) {
              Swal.fire("Success", "File Uploaded Successfully", "success");
              this.get();
            } else {
              Swal.fire("Error", data, "error");
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
    get() {
      ApiService.get(
        `business/menu/categories?limit=${this.limit}&page=${this.currentPage}&search=${this.search}`
      ).then(({ data }) => {
        this.categories = data.data;
        this.totalCategories = data.total;
        this.totalPages = Math.ceil(this.totalCategories / this.limit);
      });
    },
    edit(id) {
      this.currentId = id;
      this.$refs["category-modal"].show();
    },
    hideModal() {
      this.$refs["category-modal"].hide();
    },
    addNew() {
      this.currentId = "";
      this.$refs["category-modal"].show();
    },
    deleteItem(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          ApiService.delete(`business/menu/categories/${id}`).then(
            ({ data }) => {
              this.get();
              Swal.fire(
                "Deleted!",
                "Your category has been deleted.",
                "success"
              );
            }
          );
        }
      });
    },
    formSuccess() {
      this.hideModal();
      this.get();
      Swal.fire({
        title: "",
        text: "The category has been successfully added!",
        icon: "success"
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Categories" }
    ]);
    this.get();
  }
};
</script>
