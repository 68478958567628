<template>
  <!--begin::Card-->
  <div>
    <form
      class="form"
      novalidate="novalidate"
      id="category_form"
    >
      <div class="form-group">
        <label>Name</label>
        <input
          type="text"
          class="form-control form-control-solid form-control-lg"
          v-model="form.name"
          placeholder="Category name"
          name="name"
          ref="name"
        />
        <span class="form-text text-muted"
          >Enter category name</span
        >
      </div>
      <div class="form-group">
        <label>Description</label>
        <b-form-textarea
          id="textarea-small"
          v-model="form.description"
          class="form-control form-control-solid form-control-lg"
          size="lg"
          placeholder="Description"
          name="description"
          ref="description"
        ></b-form-textarea>
        <span class="form-text text-muted">
          Category Description
        </span>
      </div>
      <div class="row justify-content-end">
          <button
            ref="category_form_submit"
            class="btn btn-primary mt-3 mr-4"
          >
            Submit
          </button>
      </div>
    </form>
  </div>
  <!--end::Card-->
</template>

<script>

import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";

export default {
  name: "MenuCategoryForm",
  props: ['id'],
  data() {
    return {
      form: {
        name: '',
        description: '',
        sort : 1
      }

    };
  },
  mounted() {
    const category_form = KTUtil.getById("category_form");
    this.fv = formValidation(category_form, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "Category name is required"
            }
          }
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.valid", () => {
      const form = this.form;
      // set spinner to submit button
      const submitButton = this.$refs["category_form_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var url = 'business/menu/categories';

      if(this.id){
        url = 'business/menu/categories/'+this.id;
        form._method = 'PUT';
      }

      ApiService.post(url, form)
      .then(({ data }) => {
        this.$emit('success');
      })
      .catch(({ response }) => {
        Swal.fire({
          title: "",
          text: "Error occured.",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });

      //Remove Spineer
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    });

    if(this.id)
      this.get(this.id);
  },
  methods: {
    get(id) {
      ApiService.get('business/menu/categories', id)
      .then(({ data }) => {
        let category = data.data;
        this.form.name = category.name;
        this.form.description = category.description;
      });
    },
    hide() {
      this.$emit('onClickCancel');
    },
  }
};
</script>
